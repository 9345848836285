// import logo from './logo.svg';
import "./App.css";
import React, { useEffect, useState, useContext } from "react";
import { Tabs, Tab, Typography, Container, Button } from '@mui/material';
import MyLeaflet from "./MyLeaflet";
import SignInSide from "./SingIn";
import Header from "../components/header/header"
import Head from "../components/head/head"
import ImageListTable from "./ImageListTable";
import Favicon from "react-favicon";
import favicon from "../components/icons/favico.png"
import spinner from "../components/resources/spinner.gif";
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { ImagesContext } from '../utils/ImagesContext'

const { REACT_APP_API_URL_GTM } = process.env;

const useStylesModalReports = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    outline: 'none',
    borderRadius: 10,
    padding: 5,
    height: '95%',
    width: '95%',
  },
  closeButton: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
});

function GisToolMarker() {
  
  const location = useLocation();
  const classesModalReports = useStylesModalReports();
  
  const [activeTab, setActiveTab] = useState(0);
  const [cssTotal, setCssTotal] = useState(0)
  const [cssCounter, setCssCounter] = useState(0)
  const [openModalReports, setOpenModalReports] = React.useState(false);
  const [srcIframeReports,setSrcIframeReports] = React.useState("");

  const [updateDisabled,setUpdateDisabled] = React.useState(false);

  const [showWaitIframeLoad,setShowWaitIframeLoad] = React.useState(false);

  const [pathName,setPathName] = React.useState(location.pathname);

  const { campanaSeleccionada } = useContext(ImagesContext);
  

  const handleOpenModalReports = (image_set_id) => {
      setSrcIframeReports(process.env.REACT_APP_API_URL+"api/report/"+image_set_id)
      setShowWaitIframeLoad(true);
      setOpenModalReports(true);
  }
  const handleCloseModalReports = () => setOpenModalReports(false);
  useEffect(() => {
    appendScript("https://unpkg.com/leaflet@1.9.3/dist/leaflet.js");
    appendScript(
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.4.2/leaflet.draw.js"
    );
    appendScript("static/gistoolmarker/static/leaflet/js/custom.js?v=40");
  }, []);

  const hideSpinner = () => {
    setShowWaitIframeLoad(false)
}

  useEffect(() => {
      document.title = 'Nidera Vuela';
  }, []);

  const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
  };

  const TabPanel = ({ children, value, index }) => {
    return (
      
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && <Typography>{children}</Typography>}
      </div>
      
    );
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const addCssCounter = () => {
    setCssCounter(prev => prev+1)
  }
    

  return (
    <Container maxWidth="xl">        
          <Favicon url={favicon} />             
          <Head setCssTotal={setCssTotal} onLoadCss={()=>{        
            addCssCounter()
          }}/>
          <Header debug={""}/>
          <ImageListTable referer={pathName.includes("control_conteo")?'control_conteo':null} campanaSeleccionada={campanaSeleccionada} handleOpenModalReports={handleOpenModalReports} />
        {/*
        <TabPanel value={activeTab} index={1}>
          <MyLeaflet imagesSetId={90 //TODO asignar
          }/>
        </TabPanel>
        */}

        <Modal
            open={openModalReports}
            onClose={handleCloseModalReports}
            className={classesModalReports.modal}
        >            
            <div className={classesModalReports.modalContent}>            
            <Button
                variant="contained"
                color="primary"
                style={{
                    position: 'absolute',
                    top: 25,
                    right: 45,
                    zIndex:"9999"
                }}
                onClick={handleCloseModalReports}
            >
                X
            </Button>
            {
            showWaitIframeLoad
            ?
            <p
                style={{
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1000,
                textAlign:"center"
                //display: showWaitMapOver ? " " : "none",
                }}
            >
                <img
                    style={{ width: "50px", height: "50px", marginRight: "10px" }}
                    src={spinner}
                />
            </p>
            :
            ""
            }
            <iframe 
            onLoad={hideSpinner}
            style={{
                position:"fixed", 
                //top:"0", 
                //left:"0", 
                //bottom:"0", 
                //right:"0", 
                width:"93%", 
                height:"90%", 
                border:"none", 
                //margin:"0", 
                //padding:"0", 
                //overflow:"hidden", 
                zIndex:"999"
            }}
            
            src={srcIframeReports}></iframe>
            </div>
        </Modal>

      </Container>
  );
}

export default GisToolMarker;
