import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Base from "./components/base/base";
import SSO from "./components/sso/sso";
import GisToolMarker from "./gistoolmaker/App";
import Report from "./report/base";
import { ImagesContextProvider } from "./utils/ImagesContext";
import { Provider as BusProvider } from "react-bus";
import MyLeaflet from "./gistoolmaker/MyLeaflet";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <html lang="es" class="notranslate" translate="no">
  <head>
    <meta name="google" content="notranslate" />
  </head>
    <React.StrictMode>
      <BusProvider>
        <ImagesContextProvider>
          <Router>
            <Routes>
              <Route path="/sso" element={<SSO />}></Route>
              <Route path="/gistoolmarker" element={<GisToolMarker imagesSetId={81} />}></Route>
              <Route path="/control_conteo" element={<GisToolMarker />} />
              <Route path="/report" element={<Report />}></Route>
              <Route path="/*" element={<Base />} />
            </Routes>
          </Router>
        </ImagesContextProvider>
      </BusProvider>
    </React.StrictMode>
  </html>,
  rootElement
);
