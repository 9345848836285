import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Modal, Button } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { ApiFetch, SyncApiFetch } from '../utils/ApiFetch';
import { makeStyles } from '@mui/styles'
import { Container, TextField } from "@mui/material";
import MyLeaflet from "./MyLeaflet";




const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#474747",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStylesModalReports = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    outline: 'none',
    borderRadius: 10,
    padding: 5,
    height: '100%',
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
});

const ImageListTable = (props) => {
  const classesModalReports = useStylesModalReports();

  const [data, setData] = useState([]);
  const [status, setStatus] = useState({});
  const [imagesSetStatus, setImagesSetStatus] = useState({});
  const [imagesSetStatusStatus, setImagesSetStatusStatus] = useState({});
  const [sortConfig, setSortConfig] = useState({ field: null, direction: 'asc' });
  const [openModalReports, setOpenModalReports] = React.useState(false);
  const [imagesSetIdSelected, setImagesSetSelected] = React.useState(-1);
  const [searchKey, setSearchKey] = React.useState('');

  const handleCloseModalReports = () => setOpenModalReports(false);

  const statusDisabled = ['NOT_PROCESSED', 'PROCESSING', 'ERROR_CROP', 'ERROR_NO_IMAGES', 'ERROR_UNDEFINED', 'ERROR_MANUAL_DELETION', 'MANUAL_CHECKING']
  const statusReadonly = ['NOT_PROCESSED', 'PROCESSING']


  useEffect(() => {
    syncReports();
  }, []);

  useEffect(() => {
    syncReports();
  }, [props.campanaSeleccionada]);

  const syncReports = () => {
    // pide el report type 1 todo: parametrizarlo para futuros reportes
    ApiFetch('api/report_status/1', true,
      async (res) => {
        let ret = await res.json()
        setStatus(ret)
      },
      (ret) => {
        // todo: implementar
      }
    )

    ApiFetch('api/gtm/images_sets/reports/all',
      true,
      async (res) => {
        let ret = await res.json()
        console.log(ret);
        let imagesSetStatusTemp = {}
        for (let is of ret) {
          imagesSetStatusTemp[is.image_set_id] = is.processing_status
        }
        setImagesSetStatus(imagesSetStatusTemp);
        setData(ret);
      }, (ret) => {
        // todo: implementar
        //console.log(ret);
        //setIsSync(false)
        //setNoReportsMessage("Error al Sincronizar, reintente nuevamente ...")
        // todo: implementar si hay error
      },
      {
        method: "POST",
        body: JSON.stringify({
          filter:props.referer==='control_conteo'?'userlogged':null,
          campanaSeleccionada: props.campanaSeleccionada
        }),
      }
    )
  }


  const handleDownloadReportsData = (image_set_id) => {

    ApiFetch('/api/gtm/reportData/' + image_set_id,
      true,
      async (res) => {
        let ret = await res.json()
        console.log('debug zipFile',ret);

        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(ret)], {
          type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "report" + image_set_id + ".json";
        document.body.appendChild(element);
        element.click();



        // let imagesSetStatusTemp = {}
        // for (let is of ret) {
        //     imagesSetStatusTemp[is.image_set_id]=is.processing_status
        // } 
        // setImagesSetStatus(imagesSetStatusTemp);
        // setData(ret);        
      }, (ret) => {
        // todo: implementar
        //console.log(ret);
        //setIsSync(false)
        //setNoReportsMessage("Error al Sincronizar, reintente nuevamente ...")
        // todo: implementar si hay error
      })



  }

  const handleDownloadZipImages = (image_set_id) => {

    ApiFetch('/api/gtm/zipFile/' + image_set_id,
      true,
      async (res) => {
        try{
          
          let ret = await res.json()
          let url = ret.url
          if (url !== null){
            
            const element = document.createElement("a");
            
            element.href = url;
            element.download = "image" + image_set_id + ".zip";
            document.body.appendChild(element);
            element.click();
          }else{
            alert("Este dataset no ha creado el zip")    
          }
      }catch{
        alert("Este dataset no ha creado el zip")
      }


        // let imagesSetStatusTemp = {}
        // for (let is of ret) {
        //     imagesSetStatusTemp[is.image_set_id]=is.processing_status
        // } 
        // setImagesSetStatus(imagesSetStatusTemp);
        // setData(ret);        
      }, (ret) => {
        // todo: implementar
        //console.log(ret);
        //setIsSync(false)
        //setNoReportsMessage("Error al Sincronizar, reintente nuevamente ...")
        // todo: implementar si hay error
      })



  }



  const filterAllFields = (e) => {
    setSearchKey(e.target.value)
  }

  const sortData = (field) => {
    let direction = 'asc';
    if (sortConfig.field === field && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ field, direction });
  };

  const updateImagesSetStatus = (imagesSetId, newStatus) => {
    let imagesSetStatusTemp = { imagesSetStatus }
    imagesSetStatusTemp[imagesSetId] = newStatus;
    setImagesSetStatus(imagesSetStatusTemp)
    setImagesSetStatusStatus((old) => {
      old[imagesSetId] = 'MODIFIED';
      return old;
    })
  }

  const persistImagesSetStatus = (reportId, imagesSetId, newStatus) => {
    ApiFetch('api/images_sets/status/update/1/' + reportId + "/" + imagesSetId + '/' + newStatus,
      true,
      async (res) => {
        let ret = await res.json()
        setImagesSetStatusStatus((old) => {
          old[imagesSetId] = 'NOT_MODIFIED'
          return old
        })
        syncReports();
      }, (ret) => {
        // todo: implementar
      })
  }

  const sortedData = () => {
    const field = sortConfig.field;
    const direction = sortConfig.direction === 'asc' ? 1 : -1;

    if (field) {
      return [...data].sort((a, b) => {
        if (a[field] < b[field]) {
          return -1 * direction;
        }
        if (a[field] > b[field]) {
          return 1 * direction;
        }
        return 0;
      });
    }

    if (searchKey.trim() !== '') {
      let filteredData = []
      for (let row of data) {
        let flagAdd = false;
        for (let col of Object.keys(row)) {
          try {
            if (row[col] != null) {
              let val = row[col].toString().toUpperCase()
              if (val.search(searchKey.trim().toUpperCase()) !== -1) {
                flagAdd = true
              }
            }
          } catch (e) {
            console.log(e)
          }
        }
        if (flagAdd) {
          filteredData.push(row);
        }
      }
      return filteredData;
    }

    return data;
  };

  return (
    <>

      {data.length != 0 &&
        <div style={{ margin: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ paddingLeft: '20px' }}>
            MANUAL_CHECK={data.filter((v) => v.processing_status == 'MANUAL_CHECK').length}
          </div>
          <div style={{ paddingLeft: '20px' }}>
            OK={data.filter((v) => v.processing_status == 'OK').length}
          </div>
          <div style={{ paddingLeft: '20px' }}>
            PROCESSING={data.filter((v) => v.processing_status == 'PROCESSING').length}
          </div>
          <div style={{ paddingLeft: '20px' }}>
            ERROR={data.filter((v) => v.processing_status.startsWith('ERROR')).length}
          </div>

        </div>

      }

      <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
        <Table stickyHeader>
          <TableHead>
            <StyledTableRow style={{ backgroundColor: "#CCCCCC" }}>
              <StyledTableCell>
                <TableSortLabel
                  active={sortConfig.field === 'reporte_id'}
                  direction={sortConfig.field === 'reporte_id' ? sortConfig.direction : 'asc'}
                  onClick={() => sortData('reporte_id')}
                >
                  Reporte
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortConfig.field === 'user_email'}
                  direction={sortConfig.field === 'user_email' ? sortConfig.direction : 'asc'}
                  onClick={() => sortData('user_email')}
                >
                  Usuario
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortConfig.field === 'name'}
                  direction={sortConfig.field === 'name' ? sortConfig.direction : 'asc'}
                  onClick={() => sortData('name')}
                >
                   Est - Lote | Set de Imágenes
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortConfig.field === 'sampling_name'}
                  direction={sortConfig.field === 'sampling_name' ? sortConfig.direction : 'asc'}
                  onClick={() => sortData('sampling_name')}
                >
                  Sampling
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortConfig.field === 'creation_datetime'}
                  direction={sortConfig.field === 'creation_datetime' ? sortConfig.direction : 'asc'}
                  onClick={() => sortData('creation_datetime')}
                >
                  Ultima Actualización
                </TableSortLabel>
              </StyledTableCell>
              {/* <StyledTableCell style={{ width: "15%" }}>
                Calidad
              </StyledTableCell> */}
              <StyledTableCell>
                <TableSortLabel
                  active={sortConfig.field === 'processing_status'}
                  direction={sortConfig.field === 'processing_status' ? sortConfig.direction : 'asc'}
                  onClick={() => sortData('processing_status')}
                >
                  Estado
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <div style={{ verticalAlign: "middle" }}>
                  Acciones <TextField size="small" sx={{ ml: 1, width: 150, verticalAlign: "middle", fontSize: "14px" }} type="search" id="Buscar" label="Buscar" onChange={filterAllFields} />
                </div>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {sortedData().map((row) => (
              <StyledTableRow key={row.id}>
                <TableCell>{row.report_id}</TableCell>
                <TableCell>{row.user_email}</TableCell>
                <TableCell>{row.farm_name.toUpperCase()} - {row.lot_name} <br/> {row.name} (#{row.image_set_id})  </TableCell>
                <TableCell>{row.sampling_name} </TableCell>
                <TableCell>{row.creation_datetime}</TableCell>
                {/* <TableCell>
                  <div>
                    o:{' '}
                    <a style={{ color: row.original_quality.Lmax - row.original_quality.Lmin > 5 ? 'red' : 'green' }}>
                      {row.original_quality.Lmax - row.original_quality.Lmin}
                    </a>
                    {' '}|{' '}
                    {row.original_quality.CFi}/{row.original_quality.Nimg}
                    {' '}|{' '}
                    {row.original_quality.CFmean.toFixed(1)}
                  </div>
                  {row.last_quality.Nimg !== 0 &&
                    <div>
                      c:{' '}
                      <a style={{ color: row.original_quality.Lmax - row.original_quality.Lmin > 5 ? 'red' : 'green' }}>
                        {row.last_quality.Lmax - row.last_quality.Lmin}
                      </a>
                      {' '}|{' '}
                      {row.last_quality.CFi}/{row.last_quality.Nimg}
                      {' '}|{' '}
                      {row.original_quality.CFmean.toFixed(1)}
                    </div>

                  }
                </TableCell> */}
                <TableCell>
                  <div style={{width:'100%', borderBottom:'1px solid', margin:'1rem'}}>{row.processing_status} {row.motive?'('+row.motive+')':''}
                  </div>
                  <div style={{width:'100%', margin:'1rem'}}>
                  {row.processing_status === 'MANUAL_CHECK' &&
                    <Button variant="contained" sx={{ width: 100, padding: 1 }}
                      color="secondary"
                      style={{ marginLeft: "0px" }}
                      onClick={() => {
                        setImagesSetSelected(row.image_set_id)
                        setOpenModalReports(true);
                      }}>Corregir
                    </Button>
                  }
                  {row.processing_status === 'OK' &&
                    <a style={{ cursor: "pointer", verticalAlign: "middle" }}>
                      <i
                        onClick={() => {
                          props.handleOpenModalReports(row.image_set_id)
                        }}
                        style={{ color: "black", fontSize: "18px" }}
                        className="lnr lnr-eye ml-2 mr-2"
                        aria-hidden="true"
                      />{" "}

                      <i
                        onClick={() => {
                          handleDownloadReportsData(row.image_set_id)
                        }}
                        style={{ color: "black", fontSize: "18px" }}
                        className="lnr lnr-download ml-2 mr-2"
                        aria-hidden="true"
                      />{" "}

                      <i
                        onClick={() => {
                          handleDownloadZipImages(row.image_set_id)
                        }}
                        style={{ color: "black", fontSize: "18px" }}
                        className="lnr lnr-picture ml-2 mr-2"
                        aria-hidden="true"
                      />{" "}

                    </a>
                  }
                </div>


                </TableCell>
                <TableCell>
                  <select disabled={
                    statusReadonly.includes(imagesSetStatus[row.image_set_id])
                  } value={imagesSetStatus[row.image_set_id]} onChange={(event) => {
                    updateImagesSetStatus(row.image_set_id, event.target.value)
                  }}>
                    {Object.keys(status).map((statusTemp) => (
                      <option disabled={statusDisabled.includes(statusTemp)} value={statusTemp}>{statusTemp}</option>
                    ))}
                  </select><br />
                  {imagesSetStatusStatus[row.image_set_id] != null && imagesSetStatusStatus[row.image_set_id] !== 'NOT_MODIFIED' &&
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: 100, padding: 1 }}
                      style={{ marginTop: "10px" }}
                      onClick={
                        () => {
                          persistImagesSetStatus(row.report_id, row.image_set_id, imagesSetStatus[row.image_set_id])
                        }
                      }>
                      Actualizar
                    </Button>
                  }

                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        <Modal
          open={openModalReports}
          onClose={handleCloseModalReports}
          className={classesModalReports.modal}
        >
          <div className={classesModalReports.modalContent}>
            {/* <Button
                variant="contained"
                color="primary"
                style={{
                    position: 'absolute',
                    top: 25,
                    right: 45,
                    zIndex:"9999"
                }}
                onClick={handleCloseModalReports}
            >
                X
            </Button> */}
            <MyLeaflet imagesSetId={imagesSetIdSelected} handleClose={handleCloseModalReports} />
          </div>
        </Modal>

      </TableContainer>
    </>
  );
};

export default ImageListTable;
